
import { dispatchUpdateUserInfo } from '@/store/main/actions';
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class EthicsCodexModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  public checked: boolean = false;
  public formError: boolean = false;
  
  public async confirm() {
    this.formError = !this.checked;
    if (this.formError) {
      return;
    }
    await dispatchUpdateUserInfo(this.$store, {code_of_etics_status: 'chk'});
    this.$emit('close-modal', true);
  }
  
  public closeModal() {
    this.$emit('close-modal', false);
  }
}
